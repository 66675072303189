import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {HelmetProvider} from "react-helmet-async";

export default function Error404() {

    return (
        <HelmetProvider>

            <Helmet>
                <title>{`Home | ${process.env.REACT_APP_NAME}`}</title>
                <meta name="description" content="Areeb Majeed is a backend developer and entrepreneur based in Australia. He specializes in PHP, Golang, and MySQL."/>
            </Helmet>

            <Container className={"container-small"}>
                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        well, it's not here
                    </h1>

                    <p>
                        The page you are looking for does not exist or has been moved. Please use the navigation above to find what you are looking for. If you think this is a mistake, please contact me by clicking <Link to="/contact"
                                                                                                                                                                                                                             className={"link"}>here</Link>.
                    </p>

                    <img src={"/assets/images/404.png"} className={"img-center"} alt={"error 404"}/>

                </div>
            </Container>

        </HelmetProvider>
    );

}