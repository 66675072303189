import {Container, Nav, Navbar} from "react-bootstrap";
import React from "react";

export default function Footer() {

    const year = new Date().getFullYear();

    return (
        <Container className="container-small py-5 text-center footer">

            {/*<ul>*/}

            {/*    <li>*/}
            {/*        <a href={"https://x.com/areebdotcom"} target={"_blank"}>@areebdotcom</a>*/}
            {/*    </li>*/}

            {/*    <li>*/}
            {/*        <a href={"https://linkedin.com/in/areeb-majeed"} target={"_blank"}>linkedin</a>*/}
            {/*    </li>*/}

            {/*</ul>*/}

            <p className={"text-gray"}>
                &copy; {year} Areeb Majeed. All rights reserved.
            </p>

        </Container>
    );

}