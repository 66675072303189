import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {toast} from "react-hot-toast";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {HelmetProvider} from "react-helmet-async";

export default function Blog() {

    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL}posts`)
            .then(response => response.json())
            .then(data => {

                if (data.success) {

                    setPosts(data.data);
                    setLoading(false);

                } else {

                    toast.error(data.message);

                }

            })
            .catch(error => {
                toast.error("We couldn't load the posts. Please try again later.");
            });

    }, []);

    return (

        <HelmetProvider>

            <Helmet>
                <title>{`Blog | ${process.env.REACT_APP_NAME}`}</title>
                <meta name="description" content="My blog where I write about technology, programming, and other things that interest me."/>
            </Helmet>

            <Container className={"container-small"}>
                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        blog
                    </h1>

                    <p>
                        I write about technology, programming, and other things that interest me.
                    </p>

                    <Posts data={posts} loading={loading}/>

                </div>
            </Container>

        </HelmetProvider>

    );

}

function Posts({data, loading}) {

    if (loading) {

        return (
            <div>
                <p>
                    Loading...
                </p>
            </div>
        );

    }

    const convertDate = (date) => {

        return new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric"
        });

    };

    return (
        <div className={"mt-5"}>

            <ul className={"list-unstyled"}>
                {data.map((post, index) => (
                    <li key={index} className={"mb-4"}>

                        <h2 className={"h2 mb-0"}>
                            <Link to={`/blog/${post.slug}`} className={"link"}>
                                {post.title}
                            </Link>
                        </h2>

                        <div className={"text-muted fs-sm mb-3"}>
                            Posted on {convertDate(post.published_at)}
                        </div>

                        <p>
                            {post.excerpt}
                        </p>

                    </li>
                ))}
            </ul>

        </div>
    );

}