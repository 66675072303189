import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

export default function Testimonials() {

    return (
        <HelmetProvider>

            <Helmet>
                <title>{`Testimonials | ${process.env.REACT_APP_NAME}`}</title>
                <meta name="description" content="Check out what people have to say about me."/>
            </Helmet>

            <Container className={"container-small"}>

                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        what people have to say about me
                    </h1>

                    <p>
                        I have had the pleasure of working with many clients & friends over the years. Here are some of the testimonials I have received.
                    </p>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            I have had the pleasure of working alongside Areeb Majeed and can confidently say they show exceptional work ethic. Their dedication, efficiency, and attention to detail consistently impressed me. Areeb is a true asset to any team, and I highly recommend them for their outstanding work ethic and professionalism.
                        </p>
                        <p className={"testimonial-author"}>
                            - Joey, ICT Technician
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            Years ago I hired Areeb for a custom script that he made for me. I received a well made script with good support, many future customisations and something I didn't expect at the time - an awesome friend. Thanks Areeb!
                        </p>
                        <p className={"testimonial-author"}>
                            - Greg
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            I first met Areeb approximately 5 years ago. At the time, I needed someone to help build a micro-saas for our business. As you can imagine, there were plenty of devs to choose from. What stood out with Areeb was two things: first, he was soft spoken and humble - he answered questions with authority but remained open to suggestions. Second, he later proved that his knowledge on the required skills was very high. He did not sell himself, only to deliver something else. For that reason, I always pushed new projects to Areeb; he completed things on time and did honest
                            work. When asked to give this recommendation, I didn't hesitate for a second. Areeb is an asset to any company or group he works with.
                        </p>
                        <p className={"testimonial-author"}>
                            - Amir Ness
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            I have had the pleasure of knowing Areeb for some time and in that span of time, he’s helped me so much with coding and more when I needed help the most. He is patient, dedicated, supremely talented, dependable, and kind. I highly recommend his services and time. Given the chance, he will provide you with tremendous results. </p>
                        <p className={"testimonial-author"}>
                            - EJ
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            Areeb has been the best developer I have come across so far. He developed my website (e-commerce) well within the deadline and budget. Besides just the core code, it looks beautiful. 10/10 would work again.
                        </p>
                        <p className={"testimonial-author"}>
                            - Hardik Manktala
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            Thank you for the service. Areeb is fine at displaying his work along with supplying what he promises (actually, more than that). I am really satisfied with his scripts, they always please me. Cheap, fast and reliable.
                        </p>
                        <p className={"testimonial-author"}>
                            - Martin Hartz
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            Great Service and great support! I recommend this to everyone. I like his work. Areeb is very helpful and listens carefully to your needs.
                        </p>
                        <p className={"testimonial-author"}>
                            - Joel Hughes
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            I found this service on Google and after adding Areeb to Skype, he helped me with the full installation of the script. I really recommend his service, and great &amp; fast support!
                        </p>
                        <p className={"testimonial-author"}>
                            - Jacky Moens
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            I've been working with Areeb since 3 years now. Every time he manages to deliver the project at reasonable rates and before deadlines. I recommend Areeb to everyone who requires a reliable developer!
                        </p>
                        <p className={"testimonial-author"}>
                            - Hasan
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>

                            My friends know that I'm a perfectionist and that I need a certain level of professionalism when doing anything. Areeb here has met those requirements and even exceeded them by doing the work exactly how I requested it and more. Furthermore, the work he did on my projects did not end there, with him providing continued support whenever issues arose. Even though he may get the job done fast, it won't be bug-free. Then again, the only software which is bug free is the one that doesn't do anything. Also worth to mention his ever-present availability, even at weird
                            hours, he gets the job done fast.

                            <br/>
                            <br/>

                            So, if you need something custom, tailored to your needs, Areeb is the coder you need.

                            <br/>
                            <br/>

                            Wow, that one rhymed unintentionally.
                        </p>
                        <p className={"testimonial-author"}>
                            - Ivan Levente
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            As always, Areeb does an outstanding job. His experience and patience has always led to improvements.
                        </p>
                        <p className={"testimonial-author"}>
                            - Jorge Luis Gonzalez Laureano
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>

                            I started working with Areeb in July 2016, after [REDACTED] (a Swiss company) purchased Areeb’s [REDACTED] script.

                            <br/>
                            <br/>

                            No job was ever too big for Areeb and the speed at which he worked was amazing. [REDACTED] requested hundreds of changes to the basic script and then purchased a license for a second copy that needed extensive modifications.

                            <br/>
                            <br/>

                            The minor bugs could be counted on one hand and were always fixed quickly.

                            <br/>
                            <br/>

                            When my relationship with [REDACTED] ended in November, 2016, I purchased my own copy of [REDACTED]’s script and, once again, had a long list of enhancements that I wanted made. These were completed in a very timely manner with no problems.

                            <br/>
                            <br/>

                            I then purchased Areeb’s unique [REDACTED] on the basis that it would be fully integrated into the [REDACTED] script. This was achieved in a matter of just a few days and has been running solid ever since.

                            <br/>
                            <br/>

                            About one week after launching my own [REDACTED], I purchased the two [REDACTED] from [REDACTED] and needed Areeb to make another round of changes to suit the way I wanted to operate the website.

                            <br/>
                            <br/>

                            I wouldn't hesitate to recommend Areeb or any of his software products.

                        </p>

                        <p className={"testimonial-author"}>
                            - Chuck Rademacher
                        </p>

                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            I have purchased the Extended Version and I had a few problem with getting the script installed.
                            <br/>
                            <br/>
                            Areeb was very helpful in his advice to get the script install, he replied to every question I had and his replies was very fast.
                            <br/>
                            <br/>
                            My problem was my hosting never had some of the modules needed to get the script to work, but after talking to Areeb, I moved to my own VPS server and installed all the modules he suggested and now my site is up and nearly ready to roll just working on a new design before I am ready to take members.
                            <br/>
                            <br/>
                            If you are thinking of buying any script, I highly recommend Areeb and his scripts and the support you get from him.
                        </p>
                        <p className={"testimonial-author"}>
                            - Chris
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            Areeb is one of the best coders I know. I love what he makes. I have used 2 of his scripts and I'm completely satisfied. His work is robust, flexible, secure and easy to use. I highly recommend it!
                        </p>
                        <p className={"testimonial-author"}>
                            - Sergio
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            I was looking for a good, cheap and user friendly script and I searched so much, but I couldn't find any. [REDACTED] is simple, easy to use and budget effectively. The script is coded very well and runs smoothly. Very satisfied with Areeb's work.
                        </p>
                        <p className={"testimonial-author"}>
                            - [REDACTED]
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            Areeb is a highly talented developer and innovator. He's committed to his work which shows in the quality of software he delivers. I'm working with Areeb since 2016 on various projects and he never fails to amaze me.
                        </p>
                        <p className={"testimonial-author"}>
                            - Roderick Warren
                        </p>
                    </div>

                    <div className={"pt-4 pb-2 testimonial-container"}>
                        <p className={"testimonial"}>
                            Areeb surprised me with their script and support. The whole process went very fast, they keep their promises and always help when you have problem with the website. I recommend Areeb to anyone. Thanks to Areeb for the great support!
                        </p>
                        <p className={"testimonial-author"}>
                            - Clemens
                        </p>
                    </div>

                </div>

            </Container>

        </HelmetProvider>
    );

}