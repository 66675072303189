import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-hot-toast";

function GuestbookData() {

    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL}get-guestbook-messages`)
            .then(response => response.json())
            .then(data => {
                setMessages(data);
                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while fetching messages. Please try again later.");
            });

    }, []);

    return (
        <div>
            {
                loading ?
                    <div className={"text-center"}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    :
                    messages.map((message, index) => {
                        return (
                            <div key={index} className={"message"}>
                                <p>
                                    <strong>{message.name}</strong>: <span>{message.message}</span>
                                </p>
                            </div>
                        );
                    })
            }
        </div>
    );

}

function SignModal({show, handleClose}) {

    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [token, setToken] = useState("");
    const recaptchaRef = React.useRef();
    const [processing, setProcessing] = useState(false);

    const onKeyPressDown = (e) => {

        if (e.key === 'Enter') {
            e.preventDefault();
        }

    };

    const submitMessage = () => {

        setProcessing(true);

        fetch(`${process.env.REACT_APP_API_URL}sign-guestbook`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                message: message,
                token: token
            })
        })
            .then(response => response.json())
            .then(data => {

                setProcessing(false);
                recaptchaRef.current.reset();

                if (data.success) {

                    toast.success(data.message);

                    setName("");
                    setMessage("");
                    setToken("");

                    handleClose();

                } else {

                    toast.error(data.message);

                }

            })
            .catch(error => {
                toast.error("An error occurred while sending your message. Please try again later.");
            });

    };

    useEffect(() => {
        window.feather.replace();
    }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>

            <Modal.Body>

                <div className={"mb-3"}>
                    <label htmlFor={"name"} className={"form-label"}>Name</label>
                    <input type={"text"} className={"form-control"} id={"name"} required maxLength={50} onChange={(event) => setName(event.target.value)} value={name}/>
                </div>

                <div className={"mb-3"}>
                    <label htmlFor={"message"} className={"form-label"}>Message</label>
                    <textarea className={"form-control"} id={"message"} required rows={7} maxLength={255} onChange={(event) => setMessage(event.target.value)} value={message} onKeyDown={onKeyPressDown}></textarea>
                    <p className={"fs-sm"}>
                        {message.length}/255 characters
                    </p>
                </div>

                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(token) => setToken(token)}
                />

                <div className="d-flex justify-content-between mt-3">

                    <Button variant="primary" type="submit" onClick={submitMessage} disabled={processing}>
                        <i data-feather="edit-2" className="pen-icon"></i>
                        &nbsp;
                        Submit Message
                    </Button>

                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>

                </div>

            </Modal.Body>

        </Modal>
    );

}

export default function Guestbook() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <HelmetProvider>

            <Helmet>
                <title>{`Guestbook | ${process.env.REACT_APP_NAME}`}</title>
                <meta name="description" content="Checkout my guestbook and leave a message."/>
            </Helmet>

            <SignModal show={show} handleClose={handleClose}/>

            <Container className={"container-small"}>

                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        guestbook
                    </h1>

                    <p>
                        It's always nice to hear from people. If you have any comments, suggestions, or just want to say hi, please sign my guestbook below.
                    </p>

                    <p>
                        All messages are reviewed before being published.
                    </p>

                    <button className={"btn btn-primary"} onClick={handleShow}>
                        <i data-feather={"edit-2"} className={"pen-icon"}></i>
                        &nbsp;
                        Sign the Guestbook
                    </button>

                    <hr/>

                    <GuestbookData/>

                </div>

            </Container>

        </HelmetProvider>
    );

}